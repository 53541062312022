<template>
  <div>
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Modelos"
          buttonAddItemText="Novo Modelo"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :callBackModalInsert="redirectNewEvaluation"
          :dialogDelete.sync="dialogDelete"
        >
          <template slot="data-content">
            <v-row class="mt-5">
              <!-- FILTRO CAMPOS -->
            </v-row>
          </template>
          <template slot="data-table">
            <v-data-table
              :headers="headers"
              :items="modelos"
              class="elevation-5"
              :loading="loadings.loading"
              items-per-page-text="Linhas por página"
              no-results-text="Nenhum registro encontrado"
              loading-text="Carregando... Por favor, espere"
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-text': 'Linhas por página',
              }"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <!-- Visualizar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="
                                  redirectNewEvaluation(item.uuid, 'visualizar')
                                "
                              >
                                mdi-eye
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Visualizar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Edit -->
                    <v-list-item
                      v-if="!validatePermissionsAboutModelosLegiio(item)"
                    >
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="
                                  redirectNewEvaluation(item.uuid, 'editar')
                                "
                              >
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Remove -->
                    <v-list-item
                      v-if="!validatePermissionsAboutModelosLegiio(item)"
                    >
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="remover(item, true)"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Deletar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch
                  v-if="!validatePermissionsAboutModelosLegiio(item)"
                  class="mt-0 pt-0"
                  v-model="item.status"
                  @click="mudarStatus(item)"
                  :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                  color="success"
                  value="A"
                  hide-details
                  :loading="loadings.loadingStatus[`${item.id}`]"
                ></v-switch>
                <v-chip
                  v-else
                  :color="item.status == 'A' ? 'success' : 'secondary'"
                >
                  {{ item.status == "A" ? "Ativo" : "Inativo" }}
                </v-chip>
              </template>
              <template v-slot:[`item.padrao`]="{ item }">
                <v-chip
                  small
                  :color="item.padrao == 'legiio' ? 'primary' : '#1E88E5'"
                >
                  {{ item.padrao | capitalize }}
                </v-chip>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <span>
                  {{ item.created_at | dateFormatBr() }}
                  {{ item.created_at | timeFormatBr() }}h
                </span>
              </template>
              <template v-slot:no-data>
                <label>Nenhum registro encontrado</label>
              </template>
              <template v-slot:[`footer.page-text`]="items">
                {{ items.pageStart }} - {{ items.pageStop }} de
                {{ items.itemsLength }}
              </template>
            </v-data-table>
          </template>
          <!--------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">
            Excluir o registro?
          </template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn
              :loading="loadings.loadingDelete"
              color="error"
              @click="remover()"
            >
              Excluir
            </v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
        </Crud>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";

export default {
  name: "ModelosView",
  components: {
    Crud,
  },
  data: () => ({
    logotipo: logo,
    nameForm: "Novo Modelo",
    searchTextField: "",
    dialog: false,
    loadings: {
      loading: false,
      loadingTable: false,
      loadingDelete: false,
      loadingStatus: [],
    },
    dialogDelete: false,
    item: [],
    headers: [
      { text: "Nome", align: "start", value: "nome" },
      { text: "Padrão", value: "padrao" },
      { text: "Status", value: "status" },
      { text: "Criado em", value: "created_at" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
  }),

  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    modelos: {
      get() {
        return this.$store.getters["pcoModelos/getModelos"];
      },
    },
    user: {
      get() {
        return this.$store.getters["auth/getUsuario"];
      },
    },
  },

  async created() {
    this.loadings.loading = true;

    await this.$store.dispatch("pcoModelos/initialize");

    this.loadings.loading = false;
  },

  methods: {
    remover(item, openModal = false) {
      if (openModal) {
        this.item = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.loadings.loadingDelete = true;
        const indexItem = this.modelos.findIndex((el) => el.id == this.item.id);

        this.$store
          .dispatch("pcoModelos/delete", this.item.uuid)
          .then((response) => {
            if (response) {
              this.modelos.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Modelo removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover o modelo.",
            });
          })
          .finally(() => {
            this.dialogDelete = false;
            this.loadings.loadingDelete = false;
          });
      }
    },
    mudarStatus(item) {
      this.loadings.loadingStatus[item.id] = true;
      this.loadings.loadingTable = true;

      let modeloItem = {
        id: item.id,
        uuid: item.uuid,
        status: item.status == "A" ? "I" : item.status,
      };

      modeloItem._method = "PUT";
      modeloItem.url = "/pco-modelos/" + this.modelo.uuid;

      this.$store
        .dispatch("pcoModelos/salvarModelo", modeloItem)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Modelo atualizado com sucesso.",
              cor: "green",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar modelo.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadings.loadingStatus[item.id] = false;
          this.loadings.loadingTable = false;
        });
    },
    redirectNewEvaluation(uuid = null, action = "criar") {
      this.$store.dispatch("pcoModelos/resetModelo");
      this.$store.dispatch("pcoModelos/setStep", 1);
      this.$store.dispatch("pcoPerguntas/resetPerguntas");

      this.$router.push({
        name: "pco-modelos-formulario",
        params: { uuid: uuid, action: action },
      });
    },
    //DATATABLE COSTUMIZATION
    getColor(status) {
      if (status == "A" || status == "Ativo") return "green";
      else return "red";
    },
    /**
     * @description Valida se o usuário que está acessando é o responsável pelo modelo legiio. Caso não seja será retornado true
     *
     * @param Object item Objeto do modelo
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    validatePermissionsAboutModelosLegiio(item) {
      if (this.user.id == item.id_usuario) return false;

      return true;
    },
  },
};
</script>

<style scoped></style>
